import { render, staticRenderFns } from "./tag.html?vue&type=template&id=11e7a9ae&scoped=true&external"
import script from "./tag.js?vue&type=script&lang=js&external"
export * from "./tag.js?vue&type=script&lang=js&external"
import style0 from "./tag.scss?vue&type=style&index=0&id=11e7a9ae&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e7a9ae",
  null
  
)

export default component.exports