import { render, staticRenderFns } from "./orbisDetails.html?vue&type=template&id=78f1bfe8&scoped=true&external"
import script from "./orbisDetails.js?vue&type=script&lang=js&external"
export * from "./orbisDetails.js?vue&type=script&lang=js&external"
import style0 from "./orbisDetails.scss?vue&type=style&index=0&id=78f1bfe8&prod&scoped=true&lang=scss&external"
import style1 from "./index.vue?vue&type=style&index=1&id=78f1bfe8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f1bfe8",
  null
  
)

export default component.exports