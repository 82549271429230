import { render, staticRenderFns } from "./ownershipInsights.vue?vue&type=template&id=8b122e54&scoped=true"
import script from "./ownershipInsights.vue?vue&type=script&lang=js"
export * from "./ownershipInsights.vue?vue&type=script&lang=js"
import style0 from "./ownershipInsights.vue?vue&type=style&index=0&id=8b122e54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b122e54",
  null
  
)

export default component.exports