<!-- Localized -->
<template>
    <div id="companyinfo" class="mt-3" v-if="Object.keys(companyInfo).length > 0 && fetchCompanyExpanded">
        <div class="tabActions flex justify-between items-center">
            <div class="tabContainer">
                <div class="tabs space-x-2 flex-nowrap">
                    <div
                        @click="selectedInfo(tab)"
                        v-for="(tab, index) in getCompanyInfoTabs"
                        :key="index"
                        class="tab px-6"
                        :style="{
                            borderBottomColor: selected === tab ? 'var(--brand-color)' : '',
                        }"
                        @mouseover="hoveredOver = true"
                        @mouseleave="hoveredOver = false"
                    >
                        <span :class="{'text-blue-600': selected === tab, 'text-brand': ''}" class="tab-name">
                            {{ tab }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Organization'">
            <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.duns_number') }}</div>
                <div v-if="companyInfo.duns" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.duns }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.company_name') }}</div>
                <div v-if="companyInfo.primaryName" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.primaryName }}
                </div>
                <span v-else>-</span>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registered_name') }}</div>
                <div v-if="companyInfo.registeredName" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.registeredName }}
                </div>
                <span v-else>-</span>
            </div>

            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.trade_style_names') }}</div>
                <div v-if="companyInfo.tradeStyleNames.length > 0">
                    <div v-for="(company, index) in companyInfo.tradeStyleNames" :key="index" class="text-gray-500 no-underline break-all">
                        {{ company.name }}
                    </div>
                </div>
                <span v-else>-</span>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.primary_address') }}</div>
                <div v-if="companyInfo.primaryAddress" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.primaryAddress.streetAddress.line1 ? companyInfo.primaryAddress.streetAddress.line1 + ", " : "" }} {{ companyInfo.primaryAddress.streetAddress.line2 ? companyInfo.primaryAddress.streetAddress.line2 + ", " : "" }} {{ companyInfo.primaryAddress.streetAddress.streetNumber ? companyInfo.primaryAddress.streetAddress.streetNumber + ", " : "" }} {{ companyInfo.primaryAddress.postalCode ? companyInfo.primaryAddress.postalCode + ", " : "" }}
                    {{ companyInfo.primaryAddress.addressLocality.name ? companyInfo.primaryAddress.addressLocality.name + ", " : "" }} {{ companyInfo.primaryAddress.addressRegion.name ? companyInfo.primaryAddress.addressRegion.name + ", " : "" }} {{ companyInfo.primaryAddress.addressCountry.name }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registered_address') }}</div>
                <div v-if="companyInfo.registeredAddress && Object.keys(companyInfo.registeredAddress).length > 0" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.registeredAddress.streetAddress.line2 ? companyInfo.registeredAddress.streetAddress.line2 + ", " : "" }} {{ companyInfo.registeredAddress.streetAddress.streetNumber ? companyInfo.registeredAddress.streetAddress.streetNumber + ", " : "" }} {{ companyInfo.registeredAddress.postalCode ? companyInfo.registeredAddress.postalCode + ", " : "" }} {{ companyInfo.registeredAddress.addressLocality.name ? companyInfo.registeredAddress.addressLocality.name + ", " : "" }}
                    {{ companyInfo.registeredAddress.addressRegion.name ? companyInfo.registeredAddress.addressRegion.name + ", " : "" }} {{ companyInfo.registeredAddress.addressCountry.name }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.mailing_address') }}</div>
                <div v-if="companyInfo.mailingAddress && Object.keys(companyInfo.mailingAddress).length > 0" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.mailingAddress.streetAddress.line2 ? companyInfo.mailingAddress.streetAddress.line2 + ", " : "" }} {{ companyInfo.mailingAddress.streetAddress.streetNumber ? companyInfo.mailingAddress.streetAddress.streetNumber + ", " : "" }} {{ companyInfo.mailingAddress.postalCode ? companyInfo.mailingAddress.postalCode + ", " : "" }} {{ companyInfo.mailingAddress.addressLocality.name ? companyInfo.mailingAddress.addressLocality.name + ", " : "" }}
                    {{ companyInfo.mailingAddress.addressRegion.name ? companyInfo.mailingAddress.addressRegion.name + ", " : "" }} {{ companyInfo.mailingAddress.addressCountry.name }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registration_number') }}</div>
                <div v-if="companyInfo.registrationNumbers.length > 0">
                    <div v-for="(num, index) in companyInfo.registrationNumbers" :key="index" class="text-gray-500 no-underline break-all">{{ num.registrationNumber }} {{ num.typeDescription }}</div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4">
                <div class="flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_preferred_registration_number') }}</div>
                    <div v-if="companyInfo.registrationNumbers.length > 0">
                        <div v-for="(num, index) in companyInfo.registrationNumbers" :key="index" class="text-gray-500 no-underline break-all">
                            {{ num.isPreferredRegistrationNumber }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registration_location') }}</div>
                <div v-if="companyInfo.registrationNumbers && companyInfo.registrationNumbers.length > 0">
                    <div v-for="(num, index) in companyInfo.registrationNumbers" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="num.registrationLocation">{{ num.registrationLocation }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                <div class="flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.operations') }}</div>
                    <div v-if="companyInfo.operations && companyInfo.operations.length > 0">
                        <div v-for="(operate, index) in companyInfo.operations" :key="index" class="text-gray-500 no-underline break-all">
                            {{ operate.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.character_type') }}</div>
                <div v-if="companyInfo.charterType && companyInfo.charterType.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.charterType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.subject_comments') }}</div>
                <div v-if="companyInfo.subjectComments && companyInfo.subjectComments.length > 0">
                    <div v-for="(comments, index) in companyInfo.subjectComments" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-for="(comment, index) in comments.commentaries" :key="index">{{ comment.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.comment_date') }}</div>
                <div v-if="companyInfo.subjectComments && companyInfo.subjectComments.length > 0">
                    <div v-for="(comments, index) in companyInfo.subjectComments" :key="index" class="text-gray-500 no-underline break-all">
                        {{ formatDate(comments.commentDate) }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.country_code') }}</div>
                <div v-if="companyInfo.countryISOAlpha2Code" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.countryISOAlpha2Code }}
                </div>
                <div v-else>-</div>
            </div>

            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.operating_status') }}</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingStatus && companyInfo.dunsControlStatus.operatingStatus.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.operatingStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.operating_start_date') }}</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingStatus && companyInfo.dunsControlStatus.operatingStatus.startDate" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.operatingStatus.startDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.operating_sub_status') }}</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingSubStatus && companyInfo.dunsControlStatus.operatingSubStatus.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.operatingSubStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.operating_sub_start_date') }}</div>
                <div v-if="companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.operatingSubStatus && companyInfo.dunsControlStatus.operatingSubStatus.startDate" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.operatingSubStatus.startDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.detailed_operating_status') }}</div>
                <div v-if="companyInfo.dunsControlStatus.detailedOperatingStatus && companyInfo.dunsControlStatus.detailedOperatingStatus.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.detailedOperatingStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.franchise_operation_type') }}</div>
                <div v-if="companyInfo && companyInfo.franchiseOperationType && companyInfo.franchiseOperationType.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.franchiseOperationType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.telephone_number') }}</div>
                <div v-if="companyInfo.telephone.length > 0">
                    <div v-for="(phone, index) in companyInfo.telephone" :key="index" class="text-gray-500 no-underline break-all">
                        {{ phone.telephoneNumber }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.email') }}</div>
                <div v-if="companyInfo.email.length > 0">
                    <div v-for="(mail, index) in companyInfo.email" :key="index" class="text-gray-500 no-underline break-all">
                        {{ mail.address }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.certified_email') }}</div>
                <div v-if="companyInfo.certifiedEmail" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.certifiedEmail }}
                </div>
                <span v-else>-</span>
            </div>

            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.url') }}</div>
                <div v-if="companyInfo.websiteAddress.length > 0">
                    <div v-for="(site, index) in companyInfo.websiteAddress" :key="index" class="text-gray-500 no-underline break-all">
                        {{ site.url }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domain') }}</div>
                <div v-if="companyInfo.websiteAddress.length > 0">
                    <div v-for="(site, index) in companyInfo.websiteAddress" :key="index" class="text-gray-500 no-underline break-all">
                        {{ site.domainName }}
                    </div>
                </div>
                <span v-else>-</span>
            </div>
            <div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.stock_exchange') }}</div>
                    <div v-if="companyInfo.stockExchages && companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                            {{ stocks.tickerName }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.exchange_name') }}</div>
                    <div v-if="companyInfo.stockExchages && companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                            {{ stocks.exchangeName.description }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.exchange_country') }}</div>
                    <div v-if="companyInfo.stockExchages && companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                            {{ stocks.exchangeCountry.isoAlpha2Code }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_primary') }}</div>
                    <div v-if="companyInfo.stockExchages && companyInfo.stockExchanges.length > 0">
                        <div v-for="(stocks, index) in companyInfo.stockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                            {{ stocks.isPrimary }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.standardized_stock_exchanges') }}</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                        {{ stocks.tickerName }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.listing_date') }}</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                        {{ formatDate(stocks.listingDate) }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.exchange_name') }}</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index" class="text-gray-500 no-underline break-all">
                        {{ stocks.exchangeName.description }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.section_names') }}</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index">
                        <div v-if="name.name">
                            <div v-for="(names, index) in stocks.sectionNames" :key="index" class="text-gray-500 no-underline break-all">
                                {{ names.name }}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.language') }}</div>
                <div v-if="companyInfo && companyInfo.standardizedStockExchanges.length > 0">
                    <div v-for="(stocks, index) in companyInfo.standardizedStockExchanges" :key="index">
                        <div v-for="(names, index) in stocks.sectionNames" :key="index">
                            <div v-if="names.name" class="text-gray-500 no-underline break-all">
                                {{ names.language.description }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.currency') }}</div>
                <div v-if="companyInfo.defaultCurrency" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.defaultCurrency }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.preferred_language') }}</div>
                <div v-if="companyInfo.preferredLanguage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.preferredLanguage.description }}
                </div>
                <span v-else>-</span>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.third_party_assessment') }}</div>
                <div v-if="companyInfo.thirdPartyAssessment && companyInfo.thirdPartyAssessment.length > 0">
                    <div v-for="(assesment, index) in companyInfo.thirdPartyAssessment" :key="index">
                        <div v-if="assesment.description">
                            {{ assesment.description }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.assessment_date') }}</div>
                <div v-if="companyInfo.thirdPartyAssessment && companyInfo.thirdPartyAssessment.length > 0">
                    <div v-for="(assesment, index) in companyInfo.thirdPartyAssessment" :key="index">
                        <div v-if="assesment.assessmentDate">
                            {{ formatDate(assesment.assessmentDate) }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.value') }}</div>
                <div v-if="companyInfo.thirdPartyAssessment && companyInfo.thirdPartyAssessment.length > 0">
                    <div v-for="(assesment, index) in companyInfo.thirdPartyAssessment" :key="index">
                        <div v-if="assesment.assessmentDate">
                            {{ assesment.Value }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm  mr-2">{{ $t('components.tools.company_identifier.company_info.statistical_area') }}</div>
                <div class="ml-3">
                    <div class="flex mt-2">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.cbsa_name') }}</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                            {{ companyInfo.primaryAddress.statisticalArea.cbsaName }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.cbsa_code') }}</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                            {{ companyInfo.primaryAddress.statisticalArea.cbsaCode }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.economic_area_of_influence_code') }}</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0">
                            {{ companyInfo.primaryAddress.statisticalArea.economicAreaOfInfluenceCode }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.population_rank') }}</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0 && Object.keys(companyInfo.primaryAddress.statisticalArea.populationRank).length > 0">
                            {{ companyInfo.primaryAddress.statisticalArea.populationRank.rankNumber }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.rank_description') }}</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.statisticalArea && Object.keys(companyInfo.primaryAddress.statisticalArea).length > 0 && Object.keys(companyInfo.primaryAddress.statisticalArea.populationRank).length > 0">
                            {{ companyInfo.primaryAddress.statisticalArea.populationRank.rankDescription }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div class="flex mt-5" v-if="companyInfoLevel.value === 'L3'">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.location_ownership') }}</div>
                <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.locationOwnership && Object.keys(companyInfo.primaryAddress.locationOwnership).length > 0">
                    {{ companyInfo.primaryAddress.locationOwnership.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                <div class="mt-4">
                    <div class="font-medium text-sm  mr-2">{{ $t('components.tools.company_identifier.company_info.premises_area') }}</div>
                    <div class="ml-3">
                        <div class="flex mt-2">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.measurement') }}</div>
                            <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.premisesArea && Object.keys(companyInfo.primaryAddress.premisesArea).length > 0">{{ companyInfo.primaryAddress.premisesArea.measurement }} {{ companyInfo.primaryAddress.premisesArea.unitDescription }}</div>
                            <div v-else>-</div>
                        </div>
                        <div class="flex mt-2">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.reliability') }}</div>
                            <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.premisesArea && Object.keys(companyInfo.primaryAddress.premisesArea).length > 0">{{ companyInfo.primaryAddress.premisesArea.measurement }} {{ companyInfo.primaryAddress.premisesArea.reliabilityDescription }}</div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="font-medium text-sm  mr-2">{{ $t('components.tools.company_identifier.company_info.standard_address_codes') }}</div>
                        <div class="ml-3">
                            <div class="flex mt-2">
                                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_manufacturing_location') }}</div>
                                <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.standardAddressCodes && Object.keys(companyInfo.primaryAddress.standardAddressCodes).length > 0">
                                    {{ companyInfo.primaryAddress.standardAddressCodes.isManufacturingLocation }}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="flex mt-2">
                                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_registered_address') }}</div>
                                <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.standardAddressCodes && Object.keys(companyInfo.primaryAddress.standardAddressCodes).length > 0">
                                    {{ companyInfo.primaryAddress.standardAddressCodes.isRegisteredAddress }}
                                </div>
                                <div v-else>-</div>
                            </div>
                            <div class="flex mt-2">
                                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_residential_address') }}</div>
                                <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.standardAddressCodes && Object.keys(companyInfo.primaryAddress.standardAddressCodes).length > 0">
                                    {{ companyInfo.primaryAddress.standardAddressCodes.isResidentialAddress }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>

                    <div class="flex mt-4">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.congressional_districts') }}</div>
                        <div v-if="companyInfo.primaryAddress && companyInfo.primaryAddress.congressionalDistricts && companyInfo.primaryAddress.congressionalDistricts.length > 0">
                            <div v-for="(distict, index) in congressionalDistricts" :key="index">
                                {{ district.district }}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                        <div class="flex flex-col mt-1">
                            <div class="overflow-x-auto scroll">
                                <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerPrimaryNames ? 'print-overflow-table' : ''">
                                    <div class="shadow rounded border border-solid border-gray-300">
                                        <table id="formerPrimaryNames" class="formerPrimaryNamesTable min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.former_primary_names') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{
                                                        { $t('components.tools.company_identifier.company_info.start_date') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.end_date') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerPrimaryNames && companyInfo.formerPrimaryNames.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerPrimaryNames" :key="index">
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">
                                                        {{ name.name }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                        {{ $t('components.tools.company_identifier.company_info.no_data_available') }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                        <div class="flex flex-col mt-1">
                            <div class="overflow-x-auto scroll">
                                <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerPrimaryNames && companyInfo.formerPrimaryNames.length ? 'print-overflow-table' : ''">
                                    <div class="shadow rounded border border-solid border-gray-300">
                                        <table id="formerRegisteredNames" class="formerRegisteredNamesTable min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.former_registered_names') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.start_date') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.end_date') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerRegisteredNames && companyInfo.formerRegisteredNames.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerPrimaryNames" :key="index">
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">
                                                        {{ name.name }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                        {{ $t('components.tools.company_identifier.company_info.no_data_available') }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                        <div class="flex flex-col mt-1">
                            <div class="overflow-x-auto scroll">
                                <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerRegisteredAddresses && companyInfo.formerRegisteredAddresses.length ? 'print-overflow-table' : ''">
                                    <div class="shadow rounded border border-solid border-gray-300">
                                        <table id="formerRegisteredAddresses" class="formerRegisteredAddressesTable min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.former_registered_address') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.start_date') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.end_date') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerRegisteredAddresses && companyInfo.formerRegisteredAddresses.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerRegisteredAddresses" :key="index">
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">
                                                        {{ name.streetName ? name.streetName + "," : "" }}{{ name.streetAddress && name.streetAddress.line1 ? name.streetAddress.line1 + "," : "" }} {{ name.streetAddress && name.streetAddress.line2 ? name.streetAddress.line2 + "," : "" }} {{ name.streetAddress && name.streetAddress.line3 ? name.streetAddress.line3 + "," : "" }} {{ name.streetAddress && name.streetAddress.line4 ? name.streetAddress.line4 + "," : "" }}
                                                        {{ name.postalCode ? name.postalCode + "," : "" }} {{ name.addressLocality && name.addressLocality.name ? name.addressLocality.name + "," : "" }} {{ name.addressCountry.name }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                        {{ $t('components.tools.company_identifier.company_info.no_data_available') }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="companyInfoLevel.value === 'L3'">
                        <div class="flex flex-col mt-1">
                            <div class="overflow-x-auto scroll">
                                <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes" :class="companyInfo && companyInfo.formerPrimaryAddresses && companyInfo.formerPrimaryAddresses.length ? 'print-overflow-table' : ''">
                                    <div class="shadow rounded border border-solid border-gray-300">
                                        <table id="formerPrimaryAddresses" class="formerPrimaryAddressesTable min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.former_primary_address') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.start_date') }}
                                                    </th>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {{ $t('components.tools.company_identifier.company_info.end_date') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="companyInfo.formerPrimaryAddresses && companyInfo.formerPrimaryAddresses.length > 0">
                                                <!-- Odd row -->
                                                <tr v-for="(name, index) in companyInfo.formerPrimaryAddresses" :key="index">
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code']`">
                                                        {{ name.streetName ? name.streetName + "," : "" }} {{ name.streetAddress && name.streetAddress.line1 ? name.streetAddress.line1 + "," : "" }} {{ name.streetAddress && name.streetAddress.line2 ? name.streetAddress.line2 + "," : "" }} {{ name.streetAddress && name.streetAddress.line3 ? name.streetAddress.line3 + "," : "" }} {{ name.streetAddress && name.streetAddress.line4 ? name.streetAddress.line4 + "," : "" }}
                                                        {{ name.postalCode ? name.postalCode + "," : "" }} {{ name.addressLocality ? name.addressLocality.name + "," : "" }} {{ name.addressCountry.name }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['code_scheme_name']`">
                                                        {{ formatDate(name.startDate) }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" :identifier="`['details']['industry_codes'][${icIndex}]['industry_code']['description']`">
                                                        {{ formatDate(name.endDate) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                        {{ $t('components.tools.company_identifier.company_info.no_data_available') }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Legal'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.start_date') }}</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.legalForm.startDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.incorporated_date') }}</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.incorporatedDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.business_entity_type') }}</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.businessEntityType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.control_ownership_date') }}</div>
                <div v-if="companyInfo && companyInfo.controlOwnershipDate" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.controlOwnershipDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.control_ownership_type') }}</div>
                <div v-if="companyInfo && companyInfo.controlOwnershipType && companyInfo.controlOwnershipType.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.controlOwnershipType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.legal_form') }}</div>
                <div v-if="companyInfo && companyInfo.legalForm && companyInfo.legalForm.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.legalForm.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registered_legal_form') }}</div>
                <div v-if="companyInfo && companyInfo.registeredDetails && companyInfo.registeredDetails.legalForm && companyInfo.registeredDetails.legalForm.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.registeredDetails.legalForm.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registration_location') }}</div>
                <div v-if="companyInfo && companyInfo.legalForm && companyInfo.legalForm.registrationLocation && companyInfo.legalForm.registrationLocation.addressRegion" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.legalForm.registrationLocation.addressRegion }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registration_status') }}</div>
                <div v-if="companyInfo && companyInfo.registeredDetails && companyInfo.registeredDetails.legalForm && companyInfo.registeredDetails.legalForm.registrationStatus && companyInfo.registeredDetails.legalForm.registrationStatus.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.registeredDetails.legalForm.registrationStatus.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.local_legal_form') }}</div>
                <div v-if="companyInfo && companyInfo.registeredDetails && companyInfo.registeredDetails.legalForm && companyInfo.registeredDetails.legalForm.localLegalForms.length > 0" class="text-gray-500 no-underline break-all">
                    <div v-for="(local, index) in companyInfo.registeredDetails.legalForm.localLegalForms" :key="index">
                        {{ local.description }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_standalone') }}</div>
                <div v-if="companyInfo && companyInfo.isStandalone" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isStandalone }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.preferred_language') }}</div>
                <div v-if="companyInfo && companyInfo.preferredLanguage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.preferredLanguage.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.currency') }}</div>
                <div v-if="companyInfo && companyInfo.legalForm" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.defaultCurrency }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Indicators'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_agent') }}</div>
                <div v-if="companyInfo && companyInfo.isAgent" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isAgent }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_importer') }}</div>
                <div v-if="companyInfo && companyInfo.isImporter" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isImporter }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_exporter') }}</div>
                <div v-if="companyInfo && companyInfo.isExporter" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isExporter }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_marketable') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.isMarketable }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_mail_undeliverable') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.isMailUndeliverable }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_telephone_disconnected') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus.isTelephoneDisconnected" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.isTelephoneDisconnected }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_delisted') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.isDelisted }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.subject_handling_details_') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus.subjectHandlingDetails.length > 0">
                    <div v-for="(subject, index) in companyInfo.dunsControlStatus.subjectHandlingDetails" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="subject.description">{{ subject.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_fortune_1000_listed') }}</div>
                <div v-if="companyInfo && companyInfo.isFortune1000Listed" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isFortune1000Listed }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_forbes_largest_private_companies_listed') }}</div>
                <div v-if="companyInfo && companyInfo.isForbesLargestPrivateCompaniesListed" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isForbesLargestPrivateCompaniesListed }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.first_report_date') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus.firstReportDate" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.firstReportDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.record_class') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.recordClass && companyInfo.dunsControlStatus.recordClass.descriptio" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.recordClass.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_self_requested_duns') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.isSelfRequestedDUNS" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.dunsControlStatus.isSelfRequestedDUNS }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.self_request_date') }}</div>
                <div v-if="companyInfo && companyInfo.dunsControlStatus && companyInfo.dunsControlStatus.selfRequestDate" class="text-gray-500 no-underline break-all">
                    {{ formatDate(companyInfo.dunsControlStatus.selfRequestDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_small_business') }}</div>
                <div v-if="companyInfo && companyInfo.isSmallBusiness" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isSmallBusiness }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_standalone') }}</div>
                <div v-if="companyInfo && companyInfo.isStandalone" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isStandalone }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Industry'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.activities') }}</div>
                <div v-if="companyInfo && companyInfo.activities">
                    <div v-for="(activity, index) in companyInfo.activities" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="activity.description">{{ activity.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.primary_industry_code') }}</div>
                <div v-if="companyInfo && companyInfo.primaryIndustryCode" class="text-gray-500 no-underline break-all">{{ companyInfo.primaryIndustryCode.usSicV4 }} - {{ companyInfo.primaryIndustryCode.usSicV4Description }}</div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.industry_code') }}</div>
                <div v-if="companyInfo && companyInfo.industryCodes">
                    <div v-for="(code, index) in companyInfo.industryCodes" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="code.description">{{ code.code }} - {{ code.description }} ({{ code.typeDescription }})</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.unspsc_code') }}</div>
                <div v-if="companyInfo && companyInfo.unspscCodes">
                    <div v-for="(code, index) in companyInfo.unspscCodes" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="code.description">{{ code.code }} - {{ code.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_non_classified_establishment') }}</div>
                <div v-if="companyInfo && companyInfo.isNonClassifiedEstablishment" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.isNonClassifiedEstablishment }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Employees'">
            <div class="mt-4" v-for="(employee, index) in companyInfo.numberOfEmployees" :key="index">
                <div class="flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.number_of_employees') }}</div>
                    <div v-if="employee.value" class="text-gray-500 no-underline break-all">{{ employee.value }} {{ employee.informationScopeDescription ? "(" + employee.informationScopeDescription + ")" : "" }}</div>
                    <div v-else>-</div>
                </div>
                <div class="flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.employee_figures_date') }}</div>
                    <div v-if="employee.value" class="text-gray-500 no-underline break-all">
                        {{ formatDate(employee.employeeFiguresDate) }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.reliability') }}</div>
                    <div v-if="employee.value" class="text-gray-500 no-underline break-all">
                        {{ employee.reliabilityDescription }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.employee_categories') }}</div>
                    <div v-if="employee.employeeCategories.length > 0">
                        <div v-for="(employee, index) in employee.employeeCategories" :key="index" class="text-gray-500 no-underline break-all">
                            {{ employee.employmentBasisDescription }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div>
                <div class="flex mt-4">
                    <strong>{{ $t('components.tools.company_identifier.company_info.trend') }}</strong>
                    <div class="ml-2 cursor-pointer flex items-center" @click="showModal('trend-description')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top :title="$t('components.tools.company_identifier.company_info.trend_description')" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mt-4" v-for="(employee, index) in companyInfo.numberOfEmployees" :key="index">
                    <div class="mt-4" v-for="(trend, index) in employee.trend" :key="index">
                        <div class="flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.time_period') }}</div>
                            <div v-if="trend.timePeriod && trend.timePeriod.description" class="text-gray-500 no-underline break-all">
                                {{ trend.timePeriod.description }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.growth_rate') }}</div>
                            <div v-if="trend.growthRate" class="text-gray-500 no-underline break-all">
                                {{ trend.growthRate }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.value') }}</div>
                            <div v-if="trend.timePeriod && trend.value" class="text-gray-500 no-underline break-all">
                                {{ trend.value }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.reliability') }}</div>
                            <div v-if="trend.timePeriod && trend.reliabilityDescription" class="text-gray-500 no-underline break-all">
                                {{ trend.reliabilityDescription }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <strong>{{ $t('components.tools.company_identifier.company_info.global_ultimate') }}</strong>
                <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                    <div class="mt-4 flex">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.numberOfEmployees.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.globalUltimate.numberOfEmployees" :key="index">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.number_of_employees') }}</div>
                                    <div v-if="details.value" class="text-gray-500 no-underline break-all">
                                        {{ details.value }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.minimum_value') }}</div>
                                    <div v-if="details.minimumValue" class="text-gray-500 no-underline break-all">
                                        {{ details.minimumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.maximum_value') }}</div>
                                    <div v-if="details.maximumValue" class="text-gray-500 no-underline break-all">
                                        {{ details.maximumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.employee_figures_date') }}</div>
                                    <div v-if="details.employeeFiguresDate" class="text-gray-500 no-underline break-all">
                                        {{ formatDate(details.employeeFiguresDate) }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4">
                <strong>{{ $t('components.tools.company_identifier.company_info.domestic_ultimate') }}</strong>
                <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                    <div class="mt-4 flex">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.numberOfEmployees.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.domesticUltimate.numberOfEmployees" :key="index">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.number_of_employees') }}</div>
                                    <div v-if="details.value" class="text-gray-500 no-underline break-all">
                                        {{ details.value }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.minimum_value') }}</div>
                                    <div v-if="details.minimumValue" class="text-gray-500 no-underline break-all">
                                        {{ details.minimumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.maximum_value') }}</div>
                                    <div v-if="details.maximumValue" class="text-gray-500 no-underline break-all">
                                        {{ details.maximumValue }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.employee_figures_date') }}</div>
                                    <div v-if="details.employeeFiguresDate" class="text-gray-500 no-underline break-all">
                                        {{ formatDate(details.employeeFiguresDate) }}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4">
                <strong>{{ $t('components.tools.company_identifier.company_info.employer_designation') }}</strong>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.score_date') }}</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.scoreDate" class="text-gray-500 no-underline break-all">
                        {{ companyInfo.employerDesignation.scoreDate }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.index_score') }}</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.scoreDate" class="text-gray-500 no-underline break-all">
                        {{ companyInfo.employerDesignation.indexScore }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.designation') }}</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.designation && companyInfo.employerDesignation.designation.description" class="text-gray-500 no-underline break-all">
                        {{ companyInfo.employerDesignation.designation.description }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.reliability') }}</div>
                    <div v-if="companyInfo && companyInfo.employerDesignation && companyInfo.employerDesignation.reliability && companyInfo.employerDesignation.reliability.description" class="text-gray-500 no-underline break-all">
                        {{ companyInfo.employerDesignation.reliability.description }}
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Financials'">
            <div class="mt-4">
                <div v-if="companyInfo && companyInfo.financials" class="text-gray-500 no-underline break-all">
                    <div v-for="(financial, index) in companyInfo.financials" :key="index">
                        <div v-if="financial.yearlyRevenue">
                            <div class="mt-4 flex" v-for="(revenue, index) in financial.yearlyRevenue" :key="index">
                                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.yearly_revenue') }}</div>
                                <div v-if="revenue.value" class="text-gray-500 no-underline break-all">{{ revenue.value.toLocaleString() }} {{ revenue.currency }}</div>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_unit_code') }}</div>
                            <div v-if="financial.unitCode" class="text-gray-500 no-underline break-all">
                                {{ financial.unitCode }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_to_date') }}</div>
                            <div v-if="financial.financialStatementToDate" class="text-gray-500 no-underline break-all">
                                {{ formatDate(financial.financialStatementToDate) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_scope') }}</div>
                            <div v-if="financial.informationScopeDescription" class="text-gray-500 no-underline break-all">
                                {{ financial.informationScopeDescription }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.fiscal_year_end') }}</div>
                    <div v-if="companyInfo.fiscalYearEnd" class="text-gray-500 no-underline break-all">
                        {{ formatDate(companyInfo.fiscalYearEnd) }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate__revenue') }}</div>
                    <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.financials.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.globalUltimate.financials" :key="index">
                                <div class="mt-4 flex">
                                    <div v-if="details.yearlyRevenue">
                                        <div v-for="(revenue, index) in details.yearlyRevenue" :key="index" class="text-gray-500 no-underline break-all">{{ revenue.value }} {{ revenue.currency }}</div>
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_scope') }}</div>
                    <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.financials.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.globalUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="text-gray-500 no-underline break-all">
                                    {{ details.informationScopeDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_reliability') }}</div>
                    <div v-if="companyInfo.globalUltimate && Object.keys(companyInfo.globalUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.globalUltimate && companyInfo.globalUltimate.financials.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.globalUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="text-gray-500 no-underline break-all">
                                    {{ details.reliabilityDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate__revenue') }}</div>
                    <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.financials.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.domesticUltimate.financials" :key="index">
                                <div class="mt-4 flex">
                                    <div v-if="details.yearlyRevenue">
                                        <div v-for="(revenue, index) in details.yearlyRevenue" :key="index" class="text-gray-500 no-underline break-all">{{ revenue.value.toLocaleString() }} {{ revenue.currentcy }}</div>
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_scope') }}</div>
                    <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.financials.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.domesticUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="text-gray-500 no-underline break-all">
                                    {{ details.informationScopeDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.statement_reliability') }}</div>
                    <div v-if="companyInfo.domesticUltimate && Object.keys(companyInfo.domesticUltimate).length > 0">
                        <div v-if="companyInfo && companyInfo.domesticUltimate && companyInfo.domesticUltimate.financials.length > 0">
                            <div class="mt-4" v-for="(details, index) in companyInfo.domesticUltimate.financials" :key="index">
                                <div v-if="details.informationScopeDescription" class="text-gray-500 no-underline break-all">
                                    {{ details.reliabilityDescription }}
                                </div>
                                <div v-else>-</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="font-medium text-sm mr-2">{{ $t('components.tools.company_identifier.company_info.banks') }}</div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.name') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.name" class="text-gray-500 no-underline break-all">
                                {{ details.name }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.branch_name') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.branchName" class="text-gray-500 no-underline break-all">
                                {{ details.branchName }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.telephone_number') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.telephone && details.telephone.length > 0">
                                <div v-for="(number, index) in details.telephone" :key="index">{{ number.telephoneNumber }}</div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.address') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.address && details.address.streetAddress && details.address.streetAddress.line1" class="text-gray-500 no-underline break-all">{{ details.address.streetAddress.line1 }} {{ details.address.streetAddress.line2 }}</div>
                            <div v-else-if="details.address.addressLocality && details.address.addressLocality.name" class="text-gray-500 no-underline break-all">
                                {{ details.address.addressLocality.name }}
                            </div>
                            <div v-else-if="details.postalCode" class="text-gray-500 no-underline break-all">
                                {{ details.address.postalCode }}
                            </div>
                            <div v-else-if="details.addressRegion" class="text-gray-500 no-underline break-all">
                                {{ details.address.addressRegion.name }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.current_notable_event') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.currentNotableEvent && details.currentNotableEvent.eventType && details.currentNotableEvent.eventType.description" class="text-gray-500 no-underline break-all">
                                {{ details.currentNotableEvent.eventType.description }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registration_number') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.registrationNumbers && details.registrationNumbers.length > 0">
                                <div v-for="(number, index) in details.registrationNumbers" :key="index">{{ number.registrationNumber }}</div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.start_date') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.currentNotableEvent && details.currentNotableEvent.startDate" class="text-gray-500 no-underline break-all">
                                {{ formatDate(details.currentNotableEvent.startDate) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.last_update') }}</div>
                    <div v-if="companyInfo.banks && companyInfo.banks.length > 0">
                        <div v-for="(details, index) in companyInfo.banks" :key="index">
                            <div v-if="details.currentNotableEvent && details.currentNotableEvent.lastUpdateDate" class="text-gray-500 no-underline break-all">
                                {{ formatDate(details.currentNotableEvent.lastUpdateDate) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="font-medium text-sm  mr-2">{{ $t('components.tools.company_identifier.company_info.business_trust_index') }}</div>
                <div class="ml-3">
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.score_date') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.scoreDate" class="text-gray-500 no-underline break-all">
                            {{ formatDate(companyInfo.businessTrustIndex.scoreDate) }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.trust_index') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.trustIndex" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.trustIndex }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.trust_class') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.trustClass" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.trustClass.description }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.national_percentile') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.nationalPercentile" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.nationalPercentile }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.data_provider') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.dataProvider" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.dataProvider.description }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.score_reasons') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.scoreReasons">
                            <div v-for="(reason, index) in companyInfo.businessTrustIndex.scoreReasons" :key="index" class="text-gray-500 no-underline break-all">
                                {{ reason.description }}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div class="font-medium text-sm  mr-2">{{ $t('components.tools.company_identifier.company_info.excluded_organization') }}</div>
                <div class="ml-3">
                    <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.duns') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.excludedOrganization" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.excludedOrganization.duns }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.name') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.excludedOrganization" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.excludedOrganization.name }}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.address') }}</div>
                        <div v-if="companyInfo && companyInfo.businessTrustIndex && companyInfo.businessTrustIndex.address && companyInfo.businessTrustIndex.address.streetAddressLines" class="text-gray-500 no-underline break-all">
                            {{ companyInfo.businessTrustIndex.address.streetAddressLines.line1 }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Regulations'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.regulator_name') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.name" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.regulations.regulatorDetails.name }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.regulator_id') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.regulatorID" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.regulations.regulatorDetails.regulatorID }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.region') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.address.addressRegion" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.regulations.regulatorDetails.address.addressRegion }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registration_number') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.registrationNumber" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.regulations.regulatorDetails.registrationNumber }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.registry_url') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.regulatorDetails && companyInfo.regulations.regulatorDetails.registryURL" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.regulations.regulatorDetails.registryURL }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.candidates') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.matchCandidates && companyInfo.regulations.matchCandidates.length > 0">
                    <div v-for="(candidate, index) in companyInfo.regulations.matchCandidates" :key="index" class="text-gray-500 no-underline break-all">
                        {{ candidate.name }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.address') }}</div>
                <div v-if="companyInfo && companyInfo.regulations && companyInfo.regulations.matchCandidates && companyInfo.regulations.matchCandidates.length > 0">
                    <div v-for="(candidate, index) in companyInfo.regulations.matchCandidates.length > 0" :key="index" class="text-gray-500 no-underline break-all">
                        {{ candidate.address.streetAddress.line1 }} {{ candidate.address.streetAddress.line2 }} {{ candidate.address.addressLocality.name }} {{ candidate.address.addressCountry.name }}
                        {{ candidate.address.addressCountry.postalCode }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Assignment Model'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.is_standalone') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.isStandalone" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.isStandalone }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.family_tree_role') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.familytreeRolesPlayed && companyInfo.assignmentModel.familytreeRolesPlayed.length > 0">
                    <div v-for="(family, index) in companyInfo.assignmentModel.familytreeRolesPlayed" :key="index">
                        <div class="text-gray-500 no-underline break-all">
                            {{ family.description }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.employee_reliability') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.employeeReliability && companyInfo.assignmentModel.employeeReliability.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.employeeReliability.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.sales_reliability') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.salesReliability && companyInfo.assignmentModel.salesReliability.description" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.salesReliability.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.has_change_in_global_ultimate') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.hasChangeInGlobalUltimate" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.hasChangeInGlobalUltimate }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.physical_location__employee_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.physicalLocation.employeeCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.physicalLocation.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.physical_location__sales_amount') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.physicalLocation.salesAmount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.physicalLocation.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.linked_companies__employee_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.linkedCompanies.employeeCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.linked_companies__sales_amount') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.linkedCompanies.salesAmount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.country_group__employee_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.countryGroup.employeeCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.country_group__sales_amount') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.physicalLocation && companyInfo.assignmentModel.countryGroup.salesAmount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.linkedCompanies.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate__employee_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.employeeCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_sales_amount') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.salesAmount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_family_tree_members_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.familyTreeMembersCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.familyTreeMembersCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_industry_sectors_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.industrySectorsCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.industrySectorsCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_primary_sector__industry_code') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.primarySector && companyInfo.assignmentModel.globalUltimate.primarySector.industryCode" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.primarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_primary_sector__industry_description') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.primarySector && companyInfo.assignmentModel.globalUltimate.primarySector.industryDescription" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.primarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_primary_sector__sector_percentage') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.primarySector && companyInfo.assignmentModel.globalUltimate.primarySector.sectorPercentage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.primarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_secondary_sector__industry_code') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.secondarySector && companyInfo.assignmentModel.globalUltimate.secondarySector.industryCode" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.secondarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_secondary_sector__industry_description') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.secondarySector && companyInfo.assignmentModel.globalUltimate.secondarySector.industryDescription" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.secondarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_secondary_sector__sector_percentage') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.secondarySector && companyInfo.assignmentModel.globalUltimate.secondarySector.sectorPercentage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.secondarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.global_ultimate_unclassified_sector__sector_percentage') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.globalUltimate && companyInfo.assignmentModel.globalUltimate.unclassifiedSector && companyInfo.assignmentModel.globalUltimate.unclassifiedSector.sectorPercentage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.globalUltimate.unclassifiedSector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate__employee_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.employeeCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.employeeCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_sales_amount') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.salesAmount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.salesAmount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_family_tree_members_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.familyTreeMembersCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.familyTreeMembersCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_industry_sectors_count') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.industrySectorsCount" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.industrySectorsCount }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_primary_sector__industry_code') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.primarySector && companyInfo.assignmentModel.domesticUltimate.primarySector.industryCode" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.primarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_primary_sector__industry_description') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.primarySector && companyInfo.assignmentModel.domesticUltimate.primarySector.industryDescription" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.primarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_primary_sector__sector_percentage') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.primarySector && companyInfo.assignmentModel.domesticUltimate.primarySector.sectorPercentage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.primarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_secondary_sector__industry_code') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.secondarySector && companyInfo.assignmentModel.domesticUltimate.secondarySector.industryCode" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.secondarySector.industryCode }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_secondary_sector__industry_description') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.secondarySector && companyInfo.assignmentModel.domesticUltimate.secondarySector.industryDescription" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.secondarySector.industryDescription }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_secondary_sector__sector_percentage') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.secondarySector && companyInfo.assignmentModel.domesticUltimate.secondarySector.sectorPercentage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.secondarySector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.domestic_ultimate_unclassified_sector__sector_percentage') }}</div>
                <div v-if="companyInfo && companyInfo.assignmentModel && companyInfo.assignmentModel.domesticUltimate && companyInfo.assignmentModel.domesticUltimate.unclassifiedSector && companyInfo.assignmentModel.domesticUltimate.unclassifiedSector.sectorPercentage" class="text-gray-500 no-underline break-all">
                    {{ companyInfo.assignmentModel.domesticUltimate.unclassifiedSector.sectorPercentage }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <div v-if="selected === 'Competitors'">
            <div class="mt-4">
                <div v-if="companyInfo && companyInfo.competitors && companyInfo.competitors.length > 0">
                    <div v-for="(info, index) in companyInfo.competitors" :key="index">
                        <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.duns') }}</div>
                            <div class="text-gray-500 no-underline break-all">
                                {{ info.duns }}
                            </div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.primary_name') }}</div>
                            <div class="text-gray-500 no-underline break-all">
                                {{ info.primaryName }}
                            </div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.individual_employee_count') }}</div>
                            <div class="text-gray-500 no-underline break-all">
                                {{ info.individualEmployeeCount }}
                            </div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.sales_revenue') }}</div>
                            <div class="text-gray-500 no-underline break-all">
                                {{ info.salesRevenue }}
                            </div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.issued_share_capital_amount') }}</div>
                            <div class="text-gray-500 no-underline break-all">
                                {{ info.issuedShareCapitalAmount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="companyInfo && companyInfo.otherCompetitors && companyInfo.otherCompetitors.length > 0">
                <div v-for="(info, index) in companyInfo.otherCompetitors" :key="index">
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.other_competitors') }}</div>
                        <div class="text-gray-500 no-underline break-all">
                            {{ info.duns }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="companyInfo && companyInfo.competitors && (!companyInfo.competitors || (companyInfo.competitors && companyInfo.competitors.length === 0)) && companyInfo && companyInfo.otherCompetitors && companyInfo.otherCompetitors.length === 0" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                {{ $t('components.tools.company_identifier.company_info.no_data_available') }}
            </div>
        </div>
        <div v-if="selected === 'Summary'">
            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.summary') }}</div>
            <div v-if="companyInfo && companyInfo.summary && companyInfo.summary.length > 0">
                <div v-for="(info, index) in companyInfo.summary" :key="index">
                    <div class="mt-4 flex">
                        <div class="text-gray-500 no-underline break-all" v-html="info.text"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Multilingual'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.multilingual_primary_name_language__writing_script') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryName && companyInfo.multilingualPrimaryName.length > 0">
                    <div v-for="(name, index) in companyInfo.multilingualPrimaryName" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="name.name || name.language.description">{{ name.name }} {{ name.language.description }} {{ name.writingScript.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.multilingual_registered_names_language__writing_script') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredNames && companyInfo.multilingualRegisteredNames.length > 0">
                    <div v-for="(name, index) in companyInfo.multilingualRegisteredNames" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="name.name || name.language.description">{{ name.name }} {{ name.language.description }} {{ name.writingScript.description }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.multilingual_trade_style_names__language__priority') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualTradestyleNames && companyInfo.multilingualTradestyleNames.length > 0">
                    <div v-for="(name, index) in companyInfo.multilingualTradestyleNames" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="name.name || name.language.description">{{ name.name }} - {{ name.language.description }} - {{ name.priority }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.multilingual_primary_address_') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryAddress && companyInfo.multilingualPrimaryAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualPrimaryAddress" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="address.streetAddress.line1 || address.streetAddress.line2 || address.addressLocality.name || address.postalCode || address.addressRegion.name || address.addressCountry.name || address.continentalRegion.name">
                            {{ address.streetAddress.line1 }} {{ address.streetAddress.line2 }} {{ address.addressLocality.name }} {{ address.postalCode }} {{ address.addressRegion.name }} {{ address.addressCountry.name }} {{ address.continentalRegion && address.continentalRegion.name ? address.continentalRegion.name : "" }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.language_') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryAddress && companyInfo.multilingualPrimaryAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualPrimaryAddress" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="address.language">
                            {{ address.language }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.writing_script') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualPrimaryAddress && companyInfo.multilingualPrimaryAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualPrimaryAddress" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="address.writingScript">
                            {{ address.writingScript }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.multilingual_registered_address_') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredAddress && companyInfo.multilingualRegisteredAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualRegisteredAddress" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="address.streetAddress.line1 || address.streetAddress.line2 || address.addressLocality.name || address.postalCode || address.addressRegion.name || address.addressCountry.name || address.continentalRegion.name">
                            {{ address.streetAddress.line1 }} {{ address.streetAddress.line2 }} {{ address.addressLocality.name }} {{ address.postalCode }} {{ address.addressRegion.name }} {{ address.addressCountry.name }} {{ address.continentalRegion && address.continentalRegion.name ? address.continentalRegion.name : "" }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.language_') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredAddress && companyInfo.multilingualRegisteredAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualRegisteredAddress" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="address.language">
                            {{ address.language }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.writing_script') }}</div>
                <div v-if="companyInfo && companyInfo.multilingualRegisteredAddress && companyInfo.multilingualRegisteredAddress.length > 0">
                    <div v-for="(address, index) in companyInfo.multilingualRegisteredAddress" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="address.writingScript">
                            {{ address.writingScript }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.imperial_calendar_start_year') }}</div>
                <div v-if="companyInfo && companyInfo.imperialCalendarStartYear">
                    <div class="text-gray-500 no-underline break-all">
                        {{ companyInfo.imperialCalendarStartYear }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.multilingual_search_nameslanguagewriting_script') }}</div>
                <div v-if="companyInfo && companyInfo.multiLingualSearchNames && companyInfo.multiLingualSearchNames.length > 0">
                    <div v-for="(names, index) in companyInfo.multiLingualSearchNames" :key="index" class="text-gray-500 no-underline break-all">
                        <div>{{ name.name }} - {{ name.language.description }} - {{ name.writingScript.description }}</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.tsr_commodity_codes__language__writing_script') }}</div>
                <div v-if="companyInfo && companyInfo.tsrCommodityCodes">
                    <div v-for="(tsr, index) in companyInfo.tsrCommodityCodes" :key="index" class="text-gray-500 no-underline break-all">
                        <div v-if="tsr.commodityName">
                            {{ tsr.commodityName }}
                        </div>
                        <div v-if="tsr.language && tsr.language.description">
                            {{ tsr.language.description }}
                        </div>
                        <div v-if="tsr.writingScript && tsr.writingScript.description">
                            {{ tsr.writingScript.description }}
                        </div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.investigation_date') }}</div>
                <div v-if="companyInfo && companyInfo.investigationDate">
                    {{ formatDate(companyInfo.investigationDate) }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.company_info.tsr_report_date') }}</div>
                <div v-if="companyInfo && companyInfo.tsrReportDate">
                    {{ formatDate(tsr.tsrReportDate) }}
                </div>
                <div v-else>-</div>
            </div>
        </div>
        <modal name="trend-description">
            <div class="flex justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('trend-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>
            <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                <div class="pb-3 font-bold">{{ $t('components.tools.company_identifier.company_info.trend_description') }}</div>
                <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.company_info.time_period') }}</span>{{ $t('components.tools.company_identifier.company_info.the_specific_timeframe_during_which_the_employee_data_was_recorded') }}</div>
                <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.company_info.growth_rate') }}</span>{{ $t('components.tools.company_identifier.company_info.percentage_change_in_the_number_of_employees_from_the_base_year_to_the_trend_year') }}</div>
                <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.company_info.value') }}</span>{{ $t('components.tools.company_identifier.company_info.the_total_number_of_employees_engaged_by_an_entity_for_its_business_operations_including_various_figures_such_as_consolidated_individual_entity_or_headquarters_only') }}</div>
                <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.company_info.reliability') }}</span>{{ $t('components.tools.company_identifier.company_info.an_assessment_of_the_integrity_and_accuracy_of_the_presented_figures') }}</div>
            </div>
        </modal>
    </div>
</template>
<script>
import {DateTime} from "luxon";
import {mapGetters} from "vuex";
export default {
    name: "companyInfo",
    props: {
        companyInfo: {
            type: Object,
            default: () => {}
        },
        companyInfoLevel: {
            type: Object,
        },
        disableFetchMore: {
            type: Boolean,
        },
        fetchCompanyExpanded: {
            type: Boolean,
        },
    },
    data() {
        return {
            selected: "Organization",
        };
    },
    computed: {
        ...mapGetters(["getUserFeatures"]),
        getCompanyInfoTabs() {
            let tabs = [];
            if (this.companyInfoLevel.value === "L1") {
                tabs = ["Organization", "Indicators", "Industry", "Multilingual"];
                // this.selected = "Organization";
            } else if (this.companyInfoLevel.value === "L3" && this.disableFetchMore) {
                tabs = ["Organization", "Legal", "Indicators", "Industry", "Employees", "Financials", "Regulations", "Assignment Model", "Competitors", "Summary", "Multilingual"];
                // this.selected = "Organization";
            }
            return tabs;
        },
        dbResellerCorpRecord() {
            return this.getUserFeatures.includes("dnb_reseller__corp_record");
        },
        dbCorpRecord() {
            return this.getUserFeatures.includes("db__corp_record");
        },
    },
    methods: {
        selectedInfo(info) {
            this.selected = info;
        },
        hideModal(modalname) {
            this.$modal.hide(modalname);
        },
        showModal(modalname) {
            this.$modal.show(modalname);
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
    },
};
</script>
<style lang="scss" scoped>
.tabActions {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
}
/* .scroll-bar {
    height: 10px;
} */
.tabs {
    width: 100%;
    border-bottom: 3px solid var(--brand-accent-neutral);
    cursor: pointer;
    margin: 0.4rem 0;
    font-family: var(--brand-font);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    display: flex;
}
.tabContainer {
    width: 100%;
}

.tab {
    transform: translateY(3px);
    border-bottom: 3px solid transparent;
    transition: var(--transition-mnml);
    border-radius: var(--component-border) var(--component-border) 0 0;
    font-size: 16px;
    text-align: center;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
  width: 2px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {

  background-color: gray; /* Blue thumb */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Optional border to make it stand out */
}
</style>
