<!-- Localized -->
<template>
    <div id="financialStrength" class="mt-3" v-if="Object.keys(financialStrength).length > 0 && fetchFinancialExpanded">
            <div class="tabActions flex justify-between items-center">
                <div class="tabContainer">
                    <div class="tabs space-x-2 flex-nowrap">
                        <div
                            @click="selectedInfo(tab)" v-for="(tab, index) in getCompanyInfoTabs"
                            :key="index"
                            class="tab px-6"
                            :style="{
                                borderBottomColor: selected === tab ? 'var(--brand-color)' : '', 
                            }"
                            @mouseover="hoveredOver=true"
                            @mouseleave="hoveredOver=false"
                        >
                            <span :class="{'text-blue-600': selected === tab, 'text-brand': ''}" class="tab-name">
                                {{tab}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selected.toString() ==='D&B Rating' || selected.toString() ==='DB Rating' ">
                <!-- <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">DB Rating: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.standardRating.rating }}
                    </div>
                    <div v-else>-</div>
                </div> -->
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.rating_override_reasons') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating && financialStrength.dnbAssessment.standardRating.ratingOverrideReasons && financialStrength.dnbAssessment.standardRating.ratingOverrideReasons.length>0" class="text-gray-500 no-underline break-all">
                        <div v-for="rating,index in financialStrength.dnbAssessment.ratingOverrideReasons" :key="index">
                            {{ rating.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_date') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating" class="text-gray-500 no-underline break-all">
                        {{formatDate(financialStrength.dnbAssessment.standardRating.scoreDate) }}
                    </div>
                    <div v-else>-</div>
                </div>
                <!-- <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">Financial Strength: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.standardRating.financialStrength }}
                    </div>
                    <div v-else>-</div>
                </div> -->
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.rating_reason') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating && financialStrength.dnbAssessment.standardRating.ratingReason && financialStrength.dnbAssessment.standardRating.ratingReason.length>0" class="text-gray-500 no-underline break-all">
                        <div v-for="rating,index in financialStrength.dnbAssessment.ratingReason" :key="index">
                            {{ rating.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk__segment') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRating && financialStrength.dnbAssessment.standardRating.riskSegment" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.standardRating.riskSegment }}
                    </div>
                    <div v-else>-</div>
                    <div class="ml-2 cursor-pointer flex items-center" @click="showModal('risk-description')">
                                <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top :title="$t('components.tools.company_identifier.fin_strength.risk_description')" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.financial_condition') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.financialCondition && financialStrength.dnbAssessment.financialCondition.description" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.financialCondition.description }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.has_severe_negative_events') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.hasSevereNegativeEvents" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.hasSevereNegativeEvents }}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.is_high_risk_business') }}</div>
                    <div v-if="financialStrength.isHighRiskBusiness" class="text-gray-500 no-underline break-all">
                        {{financialStrength.isHighRiskBusiness}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                    <div class="font-medium text-sm text-gray-400 mr-2" >{{ $t('components.tools.company_identifier.fin_strength.is_deteriorating_business') }}</div>
                    <div v-if="financialStrength.isDeterioratingBusiness" class="text-gray-500 no-underline break-all">
                        {{financialStrength.isDeterioratingBusiness}}
                    </div>
                    <div v-else>-</div>
                </div>

            </div>
            <div v-if="selected ==='Other Rating'">
                <div class="flex mt-2">
                    <strong>{{ $t('components.tools.company_identifier.fin_strength.other_rating') }}</strong>
                    <div class="ml-2 cursor-pointer flex items-center" @click="showModal('other-rating')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top :title="$t('components.tools.company_identifier.fin_strength.other_rating')" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.nordic_aaa_rating') }}</div>
                    <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.rating" class="text-gray-500 no-underline break-all">
                        {{financialStrength.nordicAAARating.rating}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div v-if="financialsInfoLevel.value === 'L3'">
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_date') }}</div>
                        <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.scoreDate" class="text-gray-500 no-underline break-all">
                            {{formatDate(financialStrength.nordicAAARating.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.financial_strength') }}</div>
                        <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.financialStrength && financialStrength.nordicAAARating.financialStrength.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.nordicAAARating.financialStrength.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_segment') }}</div>
                        <div v-if="financialStrength.nordicAAARating && financialStrength.nordicAAARating.riskSegment && financialStrength.nordicAAARating.riskSegment.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.nordicAAARating.riskSegment.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.tsr_rating') }}</div>
                        <div v-if="financialStrength.tsrRating && financialStrength.tsrRating.rating" class="text-gray-500 no-underline break-all">
                            {{financialStrength.tsrRating.rating}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.rating_range') }}</div>
                        <div v-if="financialStrength.tsrRating && financialStrength.tsrRating.ratingRange" class="text-gray-500 no-underline break-all">
                            {{financialStrength.tsrRating.ratingRange}}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='Failure'">
                <div class="flex mt-2">
                    <strong>{{ $t('components.tools.company_identifier.fin_strength.failure') }}</strong>
                    <div class="ml-2 cursor-pointer flex items-center" v-if="financialsInfoLevel.value === 'L3'" @click="showModal('failure-description')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top :title="$t('components.tools.company_identifier.fin_strength.failure_description')" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.classScore" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.failureScore.classScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score_description') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.classScoreDescription" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.failureScore.classScoreDescription}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div v-if="financialsInfoLevel.value === 'L3'">
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_date') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.scoreDate" class="text-gray-500 no-underline break-all">
                            {{formatDate(financialStrength.dnbAssessment.failureScore.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.failure_score') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.nationalPercentile" class="text-gray-500 no-underline break-all">
                            {{formatDate(financialStrength.dnbAssessment.failureScore.nationalPercentile)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4"> 
                         <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_override_reasons') }}</div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.scoreOverrideReasons && financialStrength.dnbAssessment.failureScore.scoreOverrideReasons.length>0" class="text-gray-500 no-underline break-all">
                                <div v-for="score,index in financialStrength.dnbAssessment.failureScore.scoreOverrideReasons" :key="index">
                                    {{score.description? score.description: '-' }}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.raw_score') }}</div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.rawScore" class="text-gray-500 no-underline break-all">
                                {{financialStrength.dnbAssessment.failureScore.rawScore}}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_incidence_percentage') }}</div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.riskIncidencePercentage" class="text-gray-500 no-underline break-all">
                                {{financialStrength.dnbAssessment.failureScore.riskIncidencePercentage}}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_commentary') }}</div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.failureScore && financialStrength.dnbAssessment.failureScore.scoreCommentary" class="text-gray-500 no-underline break-all">
                                <div v-for="score,index in financialStrength.dnbAssessment.failureScore.scoreCommentary" :key="index">
                                    {{score.description}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='Delinquecy'">
                <div class="flex mt-2">
                    <strong>{{ $t('components.tools.company_identifier.fin_strength.delinquency') }}</strong>
                    <div class="ml-2 cursor-pointer flex items-center" v-if="financialsInfoLevel.value === 'L3'" @click="showModal('deliquency-description')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top :title="$t('components.tools.company_identifier.fin_strength.delinquency_description')" width="15" height="15" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.classScore" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.delinquencyScore.classScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score_description') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.classScoreDescription" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.delinquencyScore.classScoreDescription}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div v-if="financialsInfoLevel.value === 'L3'">
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_date') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.scoreDate" class="text-gray-500 no-underline break-all">
                            {{formatDate(financialStrength.dnbAssessment.delinquencyScore.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                     <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.delinquency_score') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.nationalPercentile" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.delinquencyScore.nationalPercentile}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.raw_score') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.rawScore" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.delinquencyScore.rawScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_incidence_percentage') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.riskIncidencePercentage" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.delinquencyScore.riskIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4"> 
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_override_reasons') }}</div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.scoreOverrideReasons && financialStrength.dnbAssessment.delinquencyScore.scoreOverrideReasons.length>0" class="text-gray-500 no-underline break-all">
                                <div v-for="score,index in financialStrength.dnbAssessment.delinquencyScore.scoreOverrideReasons" :key="index">
                                        {{score.description? score.description: '-' }}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_commentary') }}</div>
                            <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.delinquencyScore && financialStrength.dnbAssessment.delinquencyScore.scoreCommentary" class="text-gray-500 no-underline break-all">
                                <div v-for="score,index in financialStrength.dnbAssessment.delinquencyScore.scoreCommentary" :key="index">
                                    {{score.description}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selected ==='Credit Limit'">
                 <!-- <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">  Assessment Date: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.assessmentDate" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.assessmentDate}}
                    </div>
                    <div v-else>-</div>
                </div> -->
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.recommended_credit_limit') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.value" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.value.toLocaleString()}} {{financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.currency}}
                    </div>
                    <div v-else>-</div>
                </div>
                <!-- <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2"> Currency: </div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit && financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.currency" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.maximumRecommendedLimit.currency}}
                    </div>
                    <div v-else>-</div>
                </div> -->
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.average_recommended_credit_limit') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit && financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit.value" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit.value.toLocaleString()}}
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.averageRecommendedLimit.currency}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.comment') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.creditLimitRecommendation && financialStrength.dnbAssessment.creditLimitRecommendation.comment && financialStrength.dnbAssessment.creditLimitRecommendation.comment.description" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.creditLimitRecommendation.comment.description}}
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div v-if="selected ==='Viability'">
                <div class="flex mt-2">
                    <strong>{{ $t('components.tools.company_identifier.fin_strength.validity_rating') }}</strong>
                    <div class="ml-2 cursor-pointer flex items-center" @click="showModal('validity-rating')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top :title="$t('components.tools.company_identifier.fin_strength.validity_description')" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="font-medium text-md  mr-2">{{ $t('components.tools.company_identifier.fin_strength.viability_rating') }}</div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.data_depth_assessment') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.dataDepth && financialStrength.viabilityRating.dataDepth.assessment" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.dataDepth.assesment}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.data_depth_indicator') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.dataDepth && financialStrength.viabilityRating.dataDepth.dataDepthIndicator" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.dataDepth.dataDepthIndicator}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.is_financial_data_available') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssesment.viabilityRating.organizationProfile.isFinancialDataAvailable" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.organizationProfile.isFinancialDataAvailable}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.organization_profile_rating') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationProfileRating" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssesment.viabilityRating.organizationProfile.organizationProfileRating}}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="font-medium text-md mr-2">{{ $t('components.tools.company_identifier.fin_strength.portfolio_comparison_score') }}</div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.bad_rate') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.badRate" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.badRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.classScore" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.classScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score_incidence_percentage') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.classScoreIncidencePercentage" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.classScoreIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.model_segment') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.modelSegment && financialStrength.dnbAssessment.portfolioComparisonScore.modelSegment.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.modelSegment.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_level') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.riskLevel && financialStrength.dnbAssessment.portfolioComparisonScore.riskLevel.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.riskLevel.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.segment_bad_rate') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.segmentBadRate" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.segmentBadRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.rating') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.rating" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.portfolioComparisonScore.rating}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.rating_date') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.portfolioComparisonScore && financialStrength.dnbAssessment.portfolioComparisonScore.ratingDate" class="text-gray-500 no-underline break-all">
                            {{formatDate(financialStrength.dnbAssessment.portfolioComparisonScore.ratingDate)}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.organization_size_assesment') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.assesment">
                            <div  v-for="organization,index in financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.assesment" :key="index" class="text-gray-500 no-underline break-all">
                                {{organization}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.organization_size_category') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.organizationSizeCategory" class="text-gray-500 no-underline break-all">
                                {{financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize.organizationSizeCategory}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.trade_data_availability_assesment') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.organizationSize && financialStrength.dnbAssessment.viabilityRating.organizationProfile.tradeDataAvailability.assesment">
                            <div  v-for="organization,index in financialStrength.dnbAssessment.viabilityRating.organizationProfile.tradeDataAvailability.assesment" :key="index" class="text-gray-500 no-underline break-all">
                                {{organization}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.organization_size_category') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.isTradeDataAvailable && financialStrength.dnbAssessment.viabilityRating.organizationProfile.isTradeDataAvailable.organizationSizeCategory" class="text-gray-500 no-underline break-all">
                                {{financialStrength.dnbAssessment.viabilityRating.organizationProfile.tradeDataAvailability.isTradeDataAvailable}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.years_in_business') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness && financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.assesment">
                            <div  v-for="organization,index in financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.assesment" :key="index" class="text-gray-500 no-underline break-all">
                                {{organization}}
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.years_in_business_category') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityRating && financialStrength.dnbAssessment.viabilityRating.organizationProfile && financialStrength.dnbAssessment.viabilityRating.organizationProfile.isTradeDataAvailable && financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.yearsInBusinessCategory" class="text-gray-500 no-underline break-all">
                                {{financialStrength.dnbAssessment.viabilityRating.organizationProfile.yearsInBusiness.yearsInBusinessCategory}}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                 <div class="mt-4">
                    <div class="font-medium text-md mr-2">{{ $t('components.tools.company_identifier.fin_strength.viability__score') }}</div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.bad_rate') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.badRate" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.badRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.classScore" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.classScore}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score_incidence_percentage') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.classScoreIncidencePercentage" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.classScoreIncidencePercentage}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score_incidence_percentage') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.overallBadRate" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.overallBadRate}}
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_level') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.viabilityScore && financialStrength.dnbAssessment.viabilityScore.riskLevel && financialStrength.dnbAssessment.viabilityScore.riskLevel.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.viabilityScore.riskLevel.description}}
                        </div>
                        <div v-else>-</div>
                    </div>
                 </div>


            </div>
            <div v-if="selected ==='Risk Assesment'">
                <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.overall_risk__assessment') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.overallRiskAssessment && financialStrength.dnbAssessment.overallRiskAssessment.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.overallRiskAssessment.description}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.overallRiskAssessment && financialStrength.dnbAssessment.overallRiskAssessment.scoreCommentary">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_commentary__comment_label__priority') }}</div>
                            <div  v-for="score,index in financialStrength.dnbAssessment.overallRiskAssessment.scoreCommentary" :key="index" class="text-gray-500 no-underline break-all">
                                {{score.comment? score.comment.description:''}} - {{ score.commentLabel? score.commentLabel.description:''}} - {{score.priority}}
                            </div>
                        </div>
                </div>
            </div>
            <div v-if="selected ==='Lay Off Score'">
                <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_date') }}</div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.scoreDate" class="text-gray-500 no-underline break-all">
                            {{formatDate(financialStrength.layOffScore.scoreDate)}}
                        </div>
                        <div v-else>-</div>
                </div>
                 <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.probability') }}</div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.probability" class="text-gray-500 no-underline break-all">
                            {{financialStrength.layOffScore.probability}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.national_risk_percentile') }}</div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.nationalRiskPercentile" class="text-gray-500 no-underline break-all">
                            {{financialStrength.layOffScore.nationalRiskPercentile}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score__description') }}</div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.classScore" class="text-gray-500 no-underline break-all">
                            {{financialStrength.layOffScore.classScore}} {{financialStrength.layOffScore.classScoreDescription}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="font-medium text-sm text-gray-400 mr-2" >{{ $t('components.tools.company_identifier.fin_strength.peers_risk_percentile') }}</div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.peersRiskPercentile" class="text-gray-500 no-underline break-all">
                            {{financialStrength.layOffScore.peersRiskPercentile}}
                        </div>
                        <div v-else>-</div>
                </div>
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.peers_class_score') }}</div>
                        <div v-if="financialStrength && financialStrength.layOffScore && financialStrength.layOffScore.peersClassScore" class="text-gray-500 no-underline break-all">
                            {{financialStrength.layOffScore.peersClassScore}} {{ financialStrength.layOffScore.peersClassScoreDescription}}
                        </div>
                        <div v-else>-</div>
                </div>
            </div>
            <div v-if="selected ==='Delinquency Norms'">
                <div v-if="financialStrength && financialStrength.delinquencyScoreNorms && financialStrength.delinquencyScoreNorms.length>0">
                    <div v-for="(deliquency,index) in financialStrength.delinquencyScoreNorms" :key="index">
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.calculation_timestamp') }}</div>
                            <div v-if="deliquency.calculationTimestamp" class="text-gray-500 no-underline break-all">
                                <div class="text-gray-500 no-underline break-all">
                                    {{formatDate(deliquency.calculationTimestamp)}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.norms_date') }}</div>
                            <div v-if="deliquency.industryNorms && deliquency.industryNorms.normsDate" class="text-gray-500 no-underline break-all">
                                {{formatDate(deliquency.industryNorms.normsDate)}}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4">
                            <div class="font-medium text-sm  mr-2">{{ $t('components.tools.company_identifier.fin_strength.national_percentile_norms') }}</div>
                            <div class="ml-3">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.lower_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.median_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.medianScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.nationalPercentileNorms.medianScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.upper_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="font-medium text-sm mr-2">{{ $t('components.tools.company_identifier.fin_strength.raw_score_norms') }}</div>
                            <div class="ml-3">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.lower_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms  && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.lowerQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.rawScoreNorms.lowerQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.median_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.medianScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.rawScoreNorms.medianScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.upper_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.upperQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.rawScoreNorms.upperQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="font-medium text-sm mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_incidence_percentage_norms') }}</div>
                            <div class=" ml-3">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.lower_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.median_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.medianScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.riskIncidencePercentageNorms.medianScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.upper_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>{{ $t('components.tools.company_identifier.fin_strength.no_data_available') }}</div>
                
            </div>
           <div v-if="selected ==='Failure Norms'">
                <div v-if="financialStrength && financialStrength.failureScoreNorms && financialStrength.failureScoreNorms.length>0">
                    <div v-for="(deliquency,index) in financialStrength.failureScoreNorms" :key="index">
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.calculation_timestamp') }}</div>
                            <div v-if="deliquency.calculationTimestamp" class="text-gray-500 no-underline break-all">
                                <div class="text-gray-500 no-underline break-all">
                                    {{formatDate(deliquency.calculationTimestamp)}}
                                </div>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4 flex">
                            <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.norms_date') }}</div>
                            <div v-if="deliquency.industryNorms && deliquency.industryNorms.normsDate" class="text-gray-500 no-underline break-all">
                                {{formatDate(deliquency.industryNorms.normsDate)}}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="mt-4">
                            <div class="font-medium text-sm mr-2">{{ $t('components.tools.company_identifier.fin_strength.national_percentile_norms') }}</div>
                            <div class="ml-3">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.lower_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.nationalPercentileNorms.lowerQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.median_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.medianScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.nationalPercentileNorms.medianScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.upper_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.nationalPercentileNorms && deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.nationalPercentileNorms.upperQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="font-medium text-sm mr-2">{{ $t('components.tools.company_identifier.fin_strength.raw_score_norms') }}</div>
                            <div class="ml-3">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.lower_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.lowerQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.rawScoreNorms.lowerQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.median_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.medianScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.rawScoreNorms.medianScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.upper_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.rawScoreNorms && deliquency.industryNorms.rawScoreNorms.upperQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.rawScoreNorms.upperQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="font-medium text-sm mr-2">{{ $t('components.tools.company_identifier.fin_strength.risk_incidence_percentage_norms') }}</div>
                            <div class=" ml-3">
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.lower_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.riskIncidencePercentageNorms.lowerQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.median_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.medianScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.riskIncidencePercentageNorms.medianScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                                <div class="mt-4 flex">
                                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.upper_quartile_score') }}</div>
                                    <div v-if="deliquency.industryNorms && deliquency.industryNorms.riskIncidencePercentageNorms && deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore" class="text-gray-500 no-underline break-all">
                                        {{deliquency.industryNorms.riskIncidencePercentageNorms.upperQuartileScore}}
                                    </div>
                                    <div v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>{{ $t('components.tools.company_identifier.fin_strength.no_data_available') }}</div>
            </div>
            <div v-if="selected ==='Rating History'">
                <div class="mt-4 flex">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.history_rating') }}</div>
                        <div v-if="financialStrength && financialStrength.dnbAssessment && financialStrength.dnbAssessment.historyRating && financialStrength.dnbAssessment.historyRating.description" class="text-gray-500 no-underline break-all">
                            {{financialStrength.dnbAssessment.historyRating.description}}
                        </div>
                        <div v-else>-</div>
                </div>
                <!-- <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="font-medium text-sm text-gray-400 mr-2"> DB Rating - Score Date: </div>
                        <div v-if="financialStrength && financialStrength.dnbAssessment && financialStrength.dnbAssessment.standardRatingHistory && financialStrength.dnbAssessment.standardRatingHistory.length>0" class="text-gray-500 no-underline break-all">
                            <div v-for="rating,index in financialStrength.dnbAssessment.standardRatingHistory" :key="index" class="text-gray-500 no-underline break-all">
                                {{rating.rating}} {{formatDate(rating.scoreDate)}}
                            </div>
                        </div>
                        <div v-else>-</div>
                </div> -->
                <div class="mt-4 flex" v-if="financialsInfoLevel.value === 'L3'">
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.tsr_rating_history') }}</div>
                        <div v-if="financialStrength && financialStrength.tsrRatingHistory && financialStrength.tsrRatingHistory.rating" class="text-gray-500 no-underline break-all">
                            {{financialStrength.tsrRatingHistory.rating}} {{formatDate(financialStrength.tsrRatingHistory.ratingDate)}}
                        </div>
                        <div v-else>-</div>
                </div>
            </div>
            <div v-if="selected ==='Emerging Market'">
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.raw_score') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.rawScore" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.emergingMarketMediationScore.rawScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.class_score') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.classScore" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.emergingMarketMediationScore.classScore}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_date') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreDate" class="text-gray-500 no-underline break-all">
                        {{formatDate(financialStrength.dnbAssessment.emergingMarketMediationScore.scoreDate)}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_model') }}</div>
                    <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreModel && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreModel.description" class="text-gray-500 no-underline break-all">
                        {{financialStrength.dnbAssessment.emergingMarketMediationScore.scoreModel.description}}
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex"> 
                        <div class="font-medium text-sm text-gray-400 mr-2">{{ $t('components.tools.company_identifier.fin_strength.score_commentary') }}</div>
                        <div v-if="financialStrength.dnbAssessment && financialStrength.dnbAssessment.emergingMarketMediationScore && financialStrength.dnbAssessment.emergingMarketMediationScore.scoreCommentary" class="text-gray-500 no-underline break-all">
                            <div v-for="score,index in financialStrength.dnbAssessment.emergingMarketMediationScore.scoreCommentary" :key="index">
                                {{score.description}}
                            </div>
                        </div>
                        <div v-else>-</div>
                </div>
            </div>
            <modal name="risk-description">
                <div class="flex mt-2  justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('risk-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                    <div class="pb-3 font-bold">{{ $t('components.tools.company_identifier.fin_strength.risk_description') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.1') }}</span>{{ $t('components.tools.company_identifier.fin_strength.signifies_minimal_risk') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.2') }}</span>{{ $t('components.tools.company_identifier.fin_strength.represents_below_average_risk') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.3') }}</span>{{ $t('components.tools.company_identifier.fin_strength.means_above_average_risk') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.4') }}</span>{{ $t('components.tools.company_identifier.fin_strength.denotes_significant_risk') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.') }}</span>{{ $t('components.tools.company_identifier.fin_strength.indicates_insufficient_data') }}</div>
                </div>
            </modal>
            <modal name="other-rating" padding-bottom="50px">
                <div class="flex mt-2  justify-end mr-4 hover:text-brand-hover cursor-pointer overflow-y-auto scroll-bar" @click="hideModal('other-rating')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="p-5 pt-2 space-y-2 mb-3 h-full overflow-y-auto scroll-bar" >
                    <div class="pb-3 font-bold">{{ $t('components.tools.company_identifier.fin_strength.other__rating') }}</div>
                    <div v-if="financialsInfoLevel.value === 'L1'">
                            <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.aaa') }}</span>{{ $t('components.tools.company_identifier.fin_strength.highest_creditworthiness_indicating_an_exceedingly_strong_ability_to_meet_current_payment_obligations') }}</div>
                            <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.aa') }}</span>{{ $t('components.tools.company_identifier.fin_strength.good_creditworthiness_with_a_good_ability_to_meet_current_payment_obligations') }}</div>
                            <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.a') }}</span>{{ $t('components.tools.company_identifier.fin_strength.creditworthy_with_sufficient_ability_to_meet_current_payment_obligations') }}</div>
                            <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.ny') }}</span>{{ $t('components.tools.company_identifier.fin_strength.newly_started_companies_with_no_negative_information_regarding_solvency_but_requiring_close_monitoring') }}</div>
                            <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.b') }}</span>{{ $t('components.tools.company_identifier.fin_strength.credit_against_security_indicating_weak_ability_to_meet_current_payment_obligations') }}</div>
                            <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.c') }}</span>{{ $t('components.tools.company_identifier.fin_strength.credit_advised_against_with_very_poor_ability_to_meet_current_payment_obligations') }}</div>
                            <div class="mt-2 mb-5"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.ir') }}</span>{{ $t('components.tools.company_identifier.fin_strength.cannot_be_rated_due_to_missing_or_outdated_significant_information') }}</div>
                    </div>
                    <div v-if="financialsInfoLevel.value === 'L3'">
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.aaa') }}</span>{{ $t('components.tools.company_identifier.fin_strength.highest_creditworthiness_indicating_an_exceedingly_strong_ability_to_meet_current_payment_obligations') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.aa') }}</span>{{ $t('components.tools.company_identifier.fin_strength.good_creditworthiness_with_a_good_ability_to_meet_current_payment_obligations') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.a') }}</span>{{ $t('components.tools.company_identifier.fin_strength.creditworthy_with_sufficient_ability_to_meet_current_payment_obligations') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.ny') }}</span>{{ $t('components.tools.company_identifier.fin_strength.newly_started_companies_with_no_negative_information_regarding_solvency_but_requiring_close_monitoring') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.b') }}</span>{{ $t('components.tools.company_identifier.fin_strength.credit_against_security_indicating_weak_ability_to_meet_current_payment_obligations') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.c') }}</span>{{ $t('components.tools.company_identifier.fin_strength.credit_advised_against_with_very_poor_ability_to_meet_current_payment_obligations') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.ir') }}</span>{{ $t('components.tools.company_identifier.fin_strength.cannot_be_rated_due_to_missing_or_outdated_significant_information') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.') }}</span>{{ $t('components.tools.company_identifier.fin_strength.translates_to_points_for_eg_90_denotes_90_points') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.risk_segment') }}</span> </div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.1') }}</span>{{ $t('components.tools.company_identifier.fin_strength.signifies_minimal_risk') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.2') }}</span>{{ $t('components.tools.company_identifier.fin_strength.represents_below_average_risk') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.3') }}</span>{{ $t('components.tools.company_identifier.fin_strength.means_above_average_risk') }}</div>
                        <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.4') }}</span>{{ $t('components.tools.company_identifier.fin_strength.denotes_significant_risk') }}</div>
                        <div class="mt-2 mb-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.') }}</span>{{ $t('components.tools.company_identifier.fin_strength.indicates_insufficient_data') }}</div>
                    </div>
                </div>
            </modal>
            <modal name="failure-description" v-if="financialsInfoLevel.value === 'L3'">
                <div class="flex mt-2  justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('failure-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                    <div class="pb-3 font-bold">{{ $t('components.tools.company_identifier.fin_strength.failure__rating') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.failure_score') }}</span>{{ $t('components.tools.company_identifier.fin_strength.a_failure_score_of_90_indicates_that_a_company_is_less_likely_to_fail_whereas_a_failure_score_of_10_suggests_that_a_company_is_more_likely_to_fail') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.raw_score') }}</span>{{ $t('components.tools.company_identifier.fin_strength.number_predicting_the_chance_of_a_risk_happening') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.risk_incidence_percentage') }}</span>{{ $t('components.tools.company_identifier.fin_strength.the_likelihood_as_a_percentage_that_a_risk_will_occur_based_on_the_entitys_score_range_from_historical_data_analysis') }}</div>
                </div>
            </modal>
            <modal name="deliquency-description" v-if="financialsInfoLevel.value === 'L3'">
                <div class="flex mt-2  justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('deliquency-description')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                    <div class="pb-3 font-bold">{{ $t('components.tools.company_identifier.fin_strength.delinquency_description') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.raw_score') }}</span>{{ $t('components.tools.company_identifier.fin_strength.number_predicting_the_chance_of_a_risk_happening') }}</div>
                    <div class="mt-2"><span class="font-bold">{{ $t('components.tools.company_identifier.fin_strength.risk_incidence_percentage') }}</span>{{ $t('components.tools.company_identifier.fin_strength.the_likelihood_as_a_percentage_that_a_risk_will_occur_based_on_the_entitys_score_range_from_historical_data_analysis') }}</div>
                </div>
            </modal>
             <modal name="validity-rating">
                <div class="flex mt-2  justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('validity-rating')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </div>
                <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                    <div class="pb-3 font-bold">{{ $t('components.tools.company_identifier.fin_strength.validity') }}</div>
                    <div class="mt-2" v-for="(val, vi) in validitiesList" :key="vi">
                        <span class="font-bold">
                            {{val.title}}
                        </span>
                        {{val.description}}
                    </div>
                </div>
            </modal>
    </div>
</template>
<script>
import {DateTime} from "luxon";
import {mapGetters} from "vuex";
export default ({
    name: "financialStrength",
    props: {
        financialStrength: {
            type: Object,
            default: () => {}
        },
       financialsInfoLevel:{
           type:Object,
           default: () => {}
       },
       disableFetchMore:{
           type:Boolean,
       },
       fetchFinancialExpanded:{
           type:Boolean,
       }
        
    },
    data(){
        return{
            selected: "D&B Rating",
            validitiesList: [{
                title: "A:",
                description: "Highly established entities (e.g., 5+ years in operation, 50+ employees, $500K+ in sales).",
            }, {
                title: "B:",
                description: "Moderately established entities.",
            }, {
                title: "X:",
                description: " Newly established or small entities (e.g., <5 years in operation, <10 employees, <$10K in sales).",
            }, {
                title: "Y:",
                description: "Branches.",
            }, {
                title: "Z:",
                description: "Subsidiaries",
            }]
        }
    },
     computed: {
        ...mapGetters(["getUserFeatures"]),
        dbResellerCorpRecord() {
            return this.getUserFeatures.includes("dnb_reseller__corp_record");
        },
        dbCorpRecord() {
            return this.getUserFeatures.includes("db__corp_record");
        },
        getCompanyInfoTabs() {
            let tabs = [];
            if(this.financialsInfoLevel.value === "L1" && !this.dbResellerCorpRecord && this.dbCorpRecord) {
                tabs = ["D&B Rating","Other Rating","Failure","Delinquecy","Lay Off Score","Rating History"];
                // this.selected = "D&B Rating"
            } else if(this.financialsInfoLevel.value === "L1" && this.dbResellerCorpRecord && this.dbCorpRecord) {
                tabs = ["DB Rating","Other Rating","Failure","Delinquecy","Lay Off Score","Rating History"];
                // this.selected = "DB Rating"
            }
            else if(this.financialsInfoLevel.value === "L3" &&  !this.dbResellerCorpRecord && this.dbCorpRecord) {
                tabs = ["D&B Rating","Other Rating","Failure","Delinquecy","Credit Limit","Viability","Risk Assesment","Lay Off Score","Delinquency Norms", "Failure Norms", "Rating History", "Emerging Market"];
                // this.selected = "D&B Rating"
            } else if(this.financialsInfoLevel.value === "L3" && this.dbResellerCorpRecord && this.dbCorpRecord) {
                tabs = ["DB Rating","Other Rating","Failure","Delinquecy","Credit Limit","Viability","Risk Assesment","Lay Off Score","Delinquency Norms", "Failure Norms", "Rating History", "Emerging Market"];
                // this.selected = "DB Rating"
            }
            return tabs
        }
    },
    methods: {
        selectedInfo(info) {
            this.selected = info;
        },
         hideModal(modalname) {
            this.$modal.hide(modalname);
        },
        showModal(modalname) {
            this.$modal.show(modalname);
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
    }
})
</script>
<style lang="scss" scoped>
    ::v-deep {
        .vm--modal {
            padding-bottom: 20px;
        }
    }

.tabActions {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
}
/* .scroll-bar {
    height: 10px;
} */
.tabs {
    width: 100%;
    border-bottom: 3px solid var(--brand-accent-neutral);
    cursor: pointer;
    margin: 0.4rem 0;
    font-family: var(--brand-font);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    display: flex;
}
.tabContainer {
    width: 100%;
}


.tab {
    transform: translateY(3px);
    border-bottom: 3px solid transparent;
    transition: var(--transition-mnml);
    border-radius: var(--component-border) var(--component-border) 0 0;
    font-size: 16px;
    text-align: center;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
  width: 2px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {

  background-color: gray; /* Blue thumb */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Optional border to make it stand out */
}
</style>