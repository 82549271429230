import { mapGetters} from "vuex";
import scrollerIcon from "@shared/assets/icons/swiper-arrow.svg";

export default {
    name: "monitoring-cascading-timeline",
    components: {
        scrollerIcon,
    },
    props: {
        timeline: {
            type: Array,
            default: [],
        },
    },

    data() {
        return {
            prettify: function(ts) {
                return new Date(ts).toLocaleDateString("en", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                });
            },
            scrollerVisible: false,
            comparison_references: [],
            swiperScrollVisible: false,
        };
    },
    computed: {
        ...mapGetters([]),
        isMonitoringTab() {
            return this.$route.name == "monitoring";
        },
        comparisonRefs() {
            return this.comparison_references.map((el) => el.api_all_data.doc_id);
        },
        checkName() {
            return this.$route.query.check_name
        }
    },

    mounted() {
        const sliderWidth = document.querySelector(".monitoring-dateRange-slider .slider-bar")?.clientWidth;
        const swiper = document.querySelector(".monitoring-dateRange-slider .swiper-scroll");
        this.swiperScrollVisible = swiper?.clientWidth >= sliderWidth ? true : false;

        setTimeout(() => {
            const swiper = document.querySelector(".monitoring-dateRange-slider .swiper-scroll");
            swiper.scrollTo(swiper?.clientWidth, 0);
        }, 100);

        const acknowledged = this.timeline.filter((el) => el.api_all_data.changes_viewed);
        const history_acknowledged = acknowledged.filter((el) => !el.is_parent);
        if (history_acknowledged?.length) this.comparison_references.push(history_acknowledged[history_acknowledged.length - 1]);
        if (this.timeline?.length) this.comparison_references.push(this.timeline[this.timeline.length - 1]);
    },

    methods: {
        scrollLeft() {
            const swiper = document.querySelector(".monitoring-dateRange-slider .swiper-scroll");
            if (swiper.scrollLeft > 100) swiper.scrollTo(swiper.scrollLeft - 100, 0);
            else if (swiper.scrollLeft > 0) swiper.scrollTo(0, 0);
        },
        scrollRight() {
            const swiper = document.querySelector(".monitoring-dateRange-slider .swiper-scroll");
            swiper.scrollTo(swiper.scrollLeft + 100, 0);
        },
        scrollSlider(event) {
            const swiper = document.querySelector(".monitoring-dateRange-slider .swiper-scroll");
            swiper.scrollLeft += event.deltaY;
        },
        validateSelectedReferences(marker) {
            if (this.comparisonRefs.includes(marker.api_all_data.doc_id)) this.comparison_references = this.comparison_references.filter((el) => el.api_all_data.doc_id !== marker.api_all_data.doc_id);
            else {
                if (this.comparison_references.length === 2) {
                    this.$toast.error(this.$t('components.saas.monitoring.maximum_2_references_are_allowed_for_comparison'));
                    return;
                } else this.comparison_references.push(marker);
            }
        },
        getMinCardWidth() {
            const slider = document.querySelector(".monitoring-dateRange-slider .slider-bar");
            const minWidth = 128;
            if (slider) {
                const calculatedWidth = (slider.clientWidth - 64) / this.timeline.length;
                return calculatedWidth > minWidth ? calculatedWidth : minWidth;
            } else {
                return minWidth;
            }
        },
    },
};
