import NeoNewsExpanded from "@shared/saas-components/news-expanded"; // moved
import AdverseMediaExpand from "@shared/saas-components/adverse-media-expand"; // moved
import NeoCorporateRecordsMore from "@shared/saas-components/corporate-records-more"; // TODO: Update Import
import OrbisRecordsMore from "@shared/saas-components/orbis-records-more"; // TODO: Update Import

export default {
    name: "monitoring-differential-highlighter",
    components: {
        NeoNewsExpanded,
        AdverseMediaExpand,
        NeoCorporateRecordsMore,
        OrbisRecordsMore
    },
    props: {
        cardComponent: {
            type: String,
            default: "",
        },
        data: {
            type: Array,
            default: [],
        },
        meta_data: {
            type: Object,
            default: null,
        },
        differentials: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {};
    },
    computed: {
        isMonitoringTab() {
            return true;
        },
    },
};
