import { render, staticRenderFns } from "./orbis-records-more.html?vue&type=template&id=6b2918b7&scoped=true&external"
import script from "./orbis-records-more.js?vue&type=script&lang=js&external"
export * from "./orbis-records-more.js?vue&type=script&lang=js&external"
import style0 from "./orbis-records-more.scss?vue&type=style&index=0&id=6b2918b7&prod&scoped=true&lang=scss&external"
import style1 from "./index.vue?vue&type=style&index=1&id=6b2918b7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2918b7",
  null
  
)

export default component.exports