import mainAxios from "@/axios";
import {ADVERSE_TOOL_MAPPING, NEWS_TOOL_MAPPING, OFFSHORELEAKS_TOOL_MAPPING, CORPORATE_TOOL_MAPPING, WC_TOOL_MAPPING} from "@shared/utils/constants";
import {capitalize, uniqBy} from "lodash";

export default {
    data: () => ({
        allQueriesParams: null,
        offsetPag: 0,
        isAllRunQueries: false,
        isNextPag: true,
        limitPag: 1,
        totalRecordsInAllQueries: 0,
    }),
    methods: {
        async onRunAllQueries(params = null, elemName) {    

            if (!this.isNextPag) {
                return;
            }
            this.isAllRunQueries = true;
            if (params) {
                this.allQueriesParams = params;
            }
            this.allQueriesParams.offset = this.offsetPag * 100;
            this.allQueriesParams.limit = this.limitPag * 100;
            if (this.filters && this.filters.saved !== "all") {
                this.allQueriesParams.saved = this.filters.saved === "saved";
            } else {
                delete this.allQueriesParams.saved;
            }
            if (this.filters && this.filters.monitoring !== "all") {
                this.allQueriesParams.monitoring_status = this.filters.monitoring === "monitoring";
            } else {
                delete this.allQueriesParams.monitoring_status;
            }
            try {
                let loaderName = "";
                let indexName = "";
                if (this.allQueriesParams.tool === "adverse-media-tool" || 
                this.allQueriesParams.tool === "lexis-bridger-tool" || 
                this.allQueriesParams.tool === "world-compliance-tool") {
                    loaderName = "nameSearch";
                    indexName = "curr_index";
                }
                if (this.allQueriesParams.tool === "company-name") {
                    loaderName = "companySearch";
                    indexName = "curr_index";
                }
                if (this.allQueriesParams.tool === "orbis-tool") {
                    loaderName = "companySearch";
                    indexName = "curr_index";
                }
                if (this.allQueriesParams.tool === "offshoreleaks-tool") {
                    loaderName = "nameSearch";
                    indexName = "curr_index";
                }
                if (this.allQueriesParams.tool === "news-tool") {
                    loaderName = "news";
                    indexName = "curr_index";
                }
                this.startLoader(loaderName);
                this.isLoading = true;
                this.showResult = true;
                this.showSearch = false;
                const {data} = await mainAxios.post("/service/all/query/results", this.allQueriesParams);
                this.totalRecordsInAllQueries = data.total_result;
                const dnbExists = data.data.some((res)=>res.source_name.includes('tool-dnb'))
                if (dnbExists) {
                   this.dnbLicenseLevel()
                }
                // this[elemName] = [];
                if (data?.data?.length > 0) {
                    const world_check = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-worldcheck")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "worldCheck",
                        ADVERSE_TOOL_MAPPING
                    );
                    const sortedResults = data?.data
                    .filter((val) => val.source_name === "tool-lexis")
                    .map((elem) => {
                        return {
                            ...elem,
                            ...{
                                query_id: elem.query_id,
                                doc_id: elem.doc_id,
                                monitoring_status: elem?.monitoring_status ? elem.monitoring_status : false,
                                saved: elem.saved,
                            },
                        };
                    })
                    let sorted = sortedResults.sort((a, b) => {
                        return b.data.BestNameScore - a.data.BestNameScore;
                    });
                    const lexis_bridger = this.getMappingValue(
                        sorted,
                        "lexisBridger",
                        ADVERSE_TOOL_MAPPING
                    );
                    const wcResults = data?.data
                    .filter((val) => val.source_name === "tool-worldcompliance")
                    .map((elem) => {
                        return {
                            ...elem.data,
                            ...{
                                query_id: elem.query_id,
                                doc_id: elem.data.doc_id,
                                monitoring_status: elem?.monitoring_status ? elem.monitoring_status : false,
                                saved: elem.saved,
                            },
                        };
                    })
                   
                   
                    const world_compliance = this.getMappingValue(
                        wcResults,
                        "worldCompliance",
                        WC_TOOL_MAPPING
                    );
                    const comply_advantage = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-complyadvantage")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "complyAdvantage",
                        ADVERSE_TOOL_MAPPING
                    );
                    const dow_jones = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-dowjones")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "dowJones",
                        ADVERSE_TOOL_MAPPING
                    );
                    const offshore_leaks = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-offshoreleaks")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "offshoreLeaks",
                        OFFSHORELEAKS_TOOL_MAPPING
                    );
                    const news = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-news")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "news",
                        NEWS_TOOL_MAPPING,
                        "News"
                    );
                    const factiva_news = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-dowjonesfactiva")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "dowJonesFactiva",
                        NEWS_TOOL_MAPPING,
                        "DowJones Factiva"
                    );
                    const openCorporates = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "openCorporates")
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "openCorporates",
                        CORPORATE_TOOL_MAPPING,
                    );

                    let bvd_person = data?.data
                        .filter((val) => val.source_name === "tool-bvd" && val.data.officer_bvd_id)
                        .map((elem) => {
                            return {
                                ...elem.data,
                                ...{
                                    officerName: elem.data.CONTACTS_HEADER_FULLNAME,
                                    directorId: elem.data.CONTACTS_HEADER_IdDirector,
                                    country: elem.data.CONTACTS_HEADER_HomeCountry,
                                    query_id: elem.query_id,
                                    doc_id: elem.doc_id,
                                    monitoring_status: elem.monitoring_status,
                                    saved: elem.saved,
                                },
                            };
                        });

                            
                    bvd_person = uniqBy(
                        bvd_person.map((item) => ({
                            ...item,
                            key: "officer_bvd",
                        })),
                        "CONTACTS_MEMBERSHIP_IdCompany"
                    );

                    bvd_person = this.getMappingValue(bvd_person, "officer_bvd", CORPORATE_TOOL_MAPPING, "bvd");
                    const bvd = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-bvd" && !val.data.officer_bvd_id)
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "bvd",
                        CORPORATE_TOOL_MAPPING,
                    );
                    let orbis_person = data?.data
                        .filter((val) => val.source_name === "orbis-tool" && val.data.officer_bvd_id)
                        .map((elem) => {
                            return {
                                ...elem.data,
                                ...{
                                    officerName: elem.data.CONTACTS_HEADER_FULLNAME,
                                    directorId: elem.data.CONTACTS_HEADER_IdDirector,
                                    country: elem.data.CONTACTS_HEADER_HomeCountry,
                                    query_id: elem.query_id,
                                    doc_id: elem.doc_id,
                                    monitoring_status: elem.monitoring_status,
                                    saved: elem.saved,
                                },
                            };
                        });

                            
                    orbis_person = uniqBy(
                        orbis_person.map((item) => ({
                            ...item,
                            key: "officer_bvd",
                        })),
                        "CONTACTS_MEMBERSHIP_IdCompany"
                    );

                    orbis_person = this.getMappingValue(orbis_person, "officer_orbis", CORPORATE_TOOL_MAPPING, "orbis");
                    const orbis = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "orbis-tool" && !val.data.officer_bvd_id)
                            .map((elem) => {
                                return {
                                    ...elem.data,
                                    ...{
                                        query_id: elem.query_id,
                                        doc_id: elem.doc_id,
                                        monitoring_status: elem.monitoring_status,
                                        saved: elem.saved,
                                    },
                                };
                            }),
                        "orbis",
                        CORPORATE_TOOL_MAPPING,
                    );
                    const db = this.getMappingValue(
                        data?.data
                            .filter((val) => val.source_name === "tool-dnb")
                            .map((elem) => {
                                if(!elem.data.matchQualityInformation){
                                    elem.data.type = "typeahead"
                                }
                                return {
                                    ...elem,
                                    ...elem.data,
                                };
                            }),
                        "db",
                        CORPORATE_TOOL_MAPPING,
                    );
                    this[elemName] = [...this[elemName], ...world_check, ...lexis_bridger, ...world_compliance, ...comply_advantage, ...dow_jones, ...offshore_leaks, ...news, ...factiva_news, ...openCorporates,  ...orbis, ...orbis_person, ...bvd_person, ...bvd, ...db];
                    this[elemName] = this[elemName].map((ele, index) => ({
                        ...ele,
                        [indexName]: index,
                        ["query"]: {
                            query_id: ele.api_all_data.query_id,
                            query_string: this.viewQueryID(ele.api_all_data.query_id),
                            search_string: this.viewQueryID(ele.api_all_data.query_id),
                        },
                    }));
                    this.cardStatusResults = this[elemName];
                    await this.updateCardStatus();
                    this.filterData();
                    this.offsetPag += 1;
                    this.limitPag += 1;
                } else {
                    this.isNextPag = false;
                }
                this.isLoading = false;
                this.resetLoader(loaderName);
            } catch (error) {
                console.log(error, "/service/all/query/results");
            }
        },

        viewQueryID(query_id) {
            let {query} = this.toolSearchQueries?.find((el) => el._id === query_id);
            if (this.allQueriesParams.tool === "adverse-media-tool") {
                return `${query?.name} ( ${query.sources?.filter((src) => src !== "offshoreleaks")?.join(", ")} )`;
            } else if (this.allQueriesParams.tool === "offshoreleaks-tool") {
                return `${query?.name} ( ${query.sources?.join(", ")} )`;
            } else if (this.allQueriesParams.tool === "news-tool") {
                let plain_query = query.query?.split(" near")[0];
                return `${plain_query} ( ${query.sources?.join(", ")} )`;
            } else if (this.allQueriesParams.tool === "company-name") {
                return query.sources?.length ? `${query.name ?? query.officer ?? query.address} (${query?.sources?.map((src) => capitalize(src))?.join(", ")})` : `${query.name ?? query.officer ?? query.address}`;
            }
            let name = Object.keys(query).filter((n) => n == "name");
            if (name) {
                query = {name: query["name"], ...query};
            }
            return Object.entries(query)
                .map((n) => (n[0] != "page_size" && n[0] != "start" ? n[1] : ""))
                .filter((n) => n)
                .join(", ");
        },
    },
};
